import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "empty-state"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Custom frequency")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.customFrequencies.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("p", null, _toDisplayString(_ctx.translate("No frequency found")), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_radio_group, {
                    modelValue: _ctx.frequencyId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.frequencyId) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customFrequencies, (customFrequency) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: customFrequency.tempExprId
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_radio, {
                              value: customFrequency.tempExprId,
                              "label-placement": "end",
                              justify: "start"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(customFrequency.description), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ])),
        _createVNode(_component_ion_fab, {
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              disabled: !_ctx.frequencyId,
              onClick: _ctx.setFrequency
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}