import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    side: "end",
    "content-id": "main-content",
    type: "overlay"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Filters")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              (_ctx.segmentSelected === 'history')
                ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(_ctx.translate("Status")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusFilters, (filter, index) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        button: "",
                        key: index,
                        onClick: ($event: any) => (_ctx.applyFilter(filter.statusId, 'status'))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "start",
                            ios: filter.iosIcon,
                            md: filter.mdIcon
                          }, null, 8, ["ios", "md"]),
                          _createVNode(_component_ion_checkbox, {
                            checked: _ctx.pipelineFilters.status.includes(filter.statusId)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate(filter.name)), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["checked"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("section", null, [
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(_ctx.translate("Category")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryFilters, (filter, index) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    button: "",
                    key: index,
                    onClick: ($event: any) => (_ctx.applyFilter(filter.enumTypeId, 'category'))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        ios: filter.iosIcon,
                        md: filter.mdIcon
                      }, null, 8, ["ios", "md"]),
                      _createVNode(_component_ion_checkbox, {
                        checked: _ctx.pipelineFilters.category.includes(filter.enumTypeId)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate(filter.name)), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["checked"])
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              (_ctx.pinnedJobs && _ctx.pinnedJobs.length)
                ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(_ctx.translate("Pinned")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pinnedJobs, (job, index) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        button: "",
                        key: index,
                        onClick: ($event: any) => (_ctx.applyFilter(job, 'enum'))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_checkbox, {
                            checked: _ctx.pipelineFilters.enum.includes(job)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getEnumName(job)), 1)
                            ]),
                            _: 2
                          }, 1032, ["checked"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}