import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-text-center"
}
const _hoisted_2 = {
  key: 1,
  class: "ion-text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Select jobs")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, {
      ref: "contentRef",
      "scroll-events": true,
      onIonScroll: _cache[2] || (_cache[2] = ($event: any) => (_ctx.enableScrolling()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_searchbar, {
          placeholder: _ctx.translate('Search jobs'),
          onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.search($event)), ["enter"]))
        }, null, 8, ["placeholder"]),
        (_ctx.queryString.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("p", null, _toDisplayString(_ctx.translate("Searched jobs will appear here")), 1)
            ]))
          : (_ctx.jobs.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(_ctx.translate("No jobs found")), 1)
              ]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.jobs, (job) => {
                return (_openBlock(), _createBlock(_component_ion_list, {
                  key: job.jobId
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { Class: "ion-text-wrap" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h2", null, _toDisplayString(job.enumName ? job.enumName : job.jobName), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (_ctx.isJobAddedToBulkScheduler(job.jobId))
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              color: "success",
                              icon: _ctx.checkmarkCircle
                            }, null, 8, ["icon"]))
                          : (_openBlock(), _createBlock(_component_ion_button, {
                              key: 1,
                              fill: "outline",
                              onClick: ($event: any) => (_ctx.addToBulkScheduler(job))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Add")), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
        _withDirectives(_createVNode(_component_ion_infinite_scroll, {
          onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadMoreJobs($event))),
          threshold: "100px",
          ref: "infiniteScrollRef"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_infinite_scroll_content, {
              "loading-spinner": "crescent",
              "loading-text": _ctx.translate('Loading')
            }, null, 8, ["loading-text"])
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.isScrollable]
        ])
      ]),
      _: 1
    }, 512)
  ], 64))
}