import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_DxpMenuFooterNavigation = _resolveComponent("DxpMenuFooterNavigation")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    side: "start",
    "content-id": "main-content",
    type: "overlay",
    disabled: !_ctx.isUserAuthenticated || _ctx.$route.path === '/login'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Job Manager")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getValidMenuItems(_ctx.appPages), (page, index) => {
                return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                  "auto-hide": "false",
                  key: index
                }, {
                  default: _withCtx(() => [
                    (page.url)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 0,
                          button: "",
                          "router-direction": "root",
                          "router-link": page.url,
                          class: _normalizeClass(["hydrated", { selected: _ctx.selectedIndex === index }])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              ios: page.iosIcon,
                              md: page.mdIcon
                            }, null, 8, ["ios", "md"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(page.title)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["router-link", "class"]))
                      : (_openBlock(), _createBlock(_component_ion_item_divider, {
                          key: 1,
                          color: "light"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { color: "medium" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate(page.title)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_DxpMenuFooterNavigation, {
        onUpdateEcomStore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setEComStore($event))),
        onUpdateShopifyConfig: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setShopifyConfig($event)))
      })
    ]),
    _: 1
  }, 8, ["disabled"]))
}