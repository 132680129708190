import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("More jobs")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
            return (_openBlock(), _createBlock(_component_ion_item, {
              key: job.jobId,
              onClick: ($event: any) => (_ctx.viewJobConfiguration(job)),
              detail: "",
              button: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(job.enumName || job.jobName), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_ion_label, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(job.statusId === "SERVICE_PENDING" ? _ctx.temporalExpr(job.tempExprId)?.description : _ctx.translate('Disabled')), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}