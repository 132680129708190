import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_JobConfigurationForBulkScheduler = _resolveComponent("JobConfigurationForBulkScheduler")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Schedule in bulk")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("section", null, [
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_subtitle, { class: "overline" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Product Store")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Stores")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select, {
                      interface: "popover",
                      value: _ctx.selectedEComStoreId,
                      onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setEComStore($event)))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.userProfile ? _ctx.userProfile.stores : []), (store) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: store.productStoreId,
                            value: store.productStoreId
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(store.storeName), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("A store represents a company or a unique catalog of products. If your OMS is connected to multiple eCommerce stores sellling different collections of products, you may have multiple Product Stores set up in HotWax Commerce.")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_subtitle, { class: "overline" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Shop Config")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("eCommerce")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shopifyConfigsForEComStore, (shopifyConfig) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    button: "",
                    key: shopifyConfig?.shopifyConfigId,
                    value: shopifyConfig?.shopifyConfigId,
                    onClick: ($event: any) => (_ctx.updateSelectedShopifyConfigs(shopifyConfig.shopId))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_checkbox, {
                        checked: _ctx.selectedShopifyConfigs.includes(shopifyConfig.shopId)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(shopifyConfig.name ? shopifyConfig.name : shopifyConfig.shopifyConfigName), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["checked"])
                    ]),
                    _: 2
                  }, 1032, ["value", "onClick"]))
                }), 128)),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("eCommerce stores are directly connected to one Shop Config. If your OMS is connected to multiple eCommerce stores selling the same catalog operating as one Company, you may have multiple Shop Configs for the selected Product Store.")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_subtitle, { class: "overline" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Shop Config")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Scheduler")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "start",
                      icon: _ctx.timeOutline
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_select, {
                      label: _ctx.translate('Run time'),
                      interface: "popover",
                      placeholder: _ctx.translate('Select run time'),
                      value: _ctx.globalRuntime,
                      onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateRunTime($event)))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runTimes, (runTime) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: runTime.value,
                            value: runTime.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate(runTime.label)), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["label", "placeholder", "value"]),
                    _createVNode(_component_ion_modal, {
                      class: "date-time-modal",
                      "is-open": _ctx.isDateTimeModalOpen,
                      onDidDismiss: _cache[3] || (_cache[3] = () => _ctx.isDateTimeModalOpen = false)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_content, { "force-overscroll": "false" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_datetime, {
                              "show-default-buttons": "",
                              "hour-cycle": "h23",
                              value: _ctx.globalRuntime ? (_ctx.isCustomRunTime(_ctx.globalRuntime) ? _ctx.getDateTime(_ctx.globalRuntime) : _ctx.getDateTime(_ctx.DateTime.now().toMillis() + _ctx.globalRuntime)) : _ctx.getNowTimestamp(),
                              onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateCustomTime($event)))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["is-open"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "start",
                      icon: _ctx.timerOutline
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_select, {
                      label: _ctx.translate('Schedule'),
                      interface: "popover",
                      "interface-options": { header: _ctx.translate('Frequency') },
                      value: _ctx.globalFreq,
                      placeholder: _ctx.translate("Schedule"),
                      onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setFrequency($event))),
                      onIonDismiss: _cache[5] || (_cache[5] = ($event: any) => (_ctx.globalFreq == 'CUSTOM' && _ctx.setCustomFrequency()))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frequencyOptions, (freq) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: freq.id,
                            value: freq.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate(freq.description)), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["label", "interface-options", "value", "placeholder"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_ion_button, {
            fill: "outline",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectJobs()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.addOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("select jobs")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("section", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulkJobs, (job) => {
              return (_openBlock(), _createBlock(_component_JobConfigurationForBulkScheduler, {
                job: job,
                key: job.jobId,
                selectedShopifyConfigs: _ctx.selectedShopifyConfigs,
                selectedEComStoreId: _ctx.selectedEComStoreId
              }, null, 8, ["job", "selectedShopifyConfigs", "selectedEComStoreId"]))
            }), 128))
          ]),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.saveChanges())),
                disabled: _ctx.bulkJobs?.length === 0
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.iceCreamOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}