import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24e80aac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, { lines: "none" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.job.enumName ? _ctx.job.enumName : _ctx.job.jobName) + " ", 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.job.description), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item_divider, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("Parameters")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Store")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_note, { slot: "end" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.eComStoreName), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("eCommerce")), 1)
            ]),
            _: 1
          }),
          (_ctx.selectedShopifyConfigs.length === 0)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 0,
                color: "danger"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("no eCommerce selected")), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_ion_note, {
                key: 1,
                slot: "end"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.shopifyConfigNames), 1)
                ]),
                _: 1
              }))
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_select, {
            label: _ctx.translate('Run time'),
            interface: "popover",
            placeholder: _ctx.translate('Select run time'),
            value: _ctx.job.runTime,
            onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateRunTime($event)))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runTimes, (runTime) => {
                return (_openBlock(), _createBlock(_component_ion_select_option, {
                  key: runTime.value,
                  value: runTime.value
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate(runTime.label)), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["label", "placeholder", "value"]),
          _createVNode(_component_ion_modal, {
            class: "date-time-modal",
            "is-open": _ctx.isDateTimeModalOpen,
            onDidDismiss: _cache[2] || (_cache[2] = () => _ctx.isDateTimeModalOpen = false)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, { "force-overscroll": "false" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_datetime, {
                    "show-default-buttons": "",
                    "hour-cycle": "h23",
                    value: _ctx.job.runTime ? (_ctx.isCustomRunTime(_ctx.job.runTime) ? _ctx.getDateTime(_ctx.job.runTime) : _ctx.getDateTime(_ctx.DateTime.now().toMillis() + _ctx.job.runTime)) : _ctx.getNowTimestamp(),
                    onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCustomTime($event)))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["is-open"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_select, {
            label: _ctx.translate('Schedule'),
            "interface-options": { header: _ctx.translate('Frequency') },
            value: _ctx.job.frequency,
            interface: "popover",
            placeholder: _ctx.translate("Bulk schedule"),
            onIonDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.job.frequency == 'CUSTOM' && _ctx.setCustomFrequency())),
            onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setFrequency($event)))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frequencyOptions, (freq) => {
                return (_openBlock(), _createBlock(_component_ion_select_option, {
                  key: freq.id,
                  value: freq.id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate(freq.description)), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["label", "interface-options", "value", "placeholder"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_button, {
          size: "small",
          fill: "outline",
          color: "danger",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeJob(_ctx.job.systemJobEnumId)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "start",
              icon: _ctx.closeOutline
            }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.translate("Remove")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}