import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a9ac016"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "desktop-only"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_MoreJobs = _resolveComponent("MoreJobs")!
  const _component_JobConfiguration = _resolveComponent("JobConfiguration")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Brokering")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("section", null, [
              _createVNode(_component_ion_button, {
                expand: "block",
                disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addBatch()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('Create new brokering job')), 1)
                ]),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.translate('Scheduled Job')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.viewJobConfiguration({ id: 'REJ_ORDR', status: _ctx.getJobStatus(_ctx.jobEnums['REJ_ORDR'])}))),
                    detail: "",
                    button: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Rejected orders")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTemporalExpression('REJ_ORDR')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.batchJobs(), (batch) => {
                return _withDirectives((_openBlock(), _createBlock(_component_ion_card, {
                  button: _ctx.isDesktop,
                  key: batch?.id,
                  detail: "",
                  onClick: ($event: any) => (_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) && _ctx.viewJobConfiguration({ id: batch.enumId, job: batch }))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_header, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_ion_card_subtitle, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getBrokerQueue(batch)), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(batch?.jobName), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        (batch.status === 'SERVICE_PENDING')
                          ? (_openBlock(), _createBlock(_component_ion_badge, {
                              key: 0,
                              class: "ion-margin-start",
                              color: "dark"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.timeFromNow(batch?.runTime)), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_list, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(batch?.temporalExpression?.description), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(batch?.runTime ? _ctx.getTime(batch.runTime) : "-"), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_toggle, {
                              disabled: "",
                              checked: _ctx.batchJobEnums[batch?.enumId].unfillable
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("Unfillable orders")), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["checked"])
                          ]),
                          _: 2
                        }, 1024),
                        (batch?.status === 'SERVICE_PENDING' && Object.keys(_ctx.generateCustomParameters(batch)).length)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_row, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generateCustomParameters(batch), (value, name, index) => {
                                      return (_openBlock(), _createBlock(_component_ion_chip, {
                                        disabled: "",
                                        outline: "",
                                        key: index
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(name) + ": " + _toDisplayString(value), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["button", "onClick"])), [
                  [_vShow, batch?.status === 'SERVICE_PENDING']
                ])
              }), 128)),
              (_ctx.getMoreJobs({..._ctx.jobEnums, ..._ctx.initialLoadJobEnums}, _ctx.enumTypeId).length)
                ? (_openBlock(), _createBlock(_component_MoreJobs, {
                    key: 0,
                    jobs: _ctx.getMoreJobs({..._ctx.jobEnums, ..._ctx.initialLoadJobEnums}, _ctx.enumTypeId)
                  }, null, 8, ["jobs"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isDesktop)
              ? _withDirectives((_openBlock(), _createElementBlock("aside", _hoisted_1, [
                  (_openBlock(), _createBlock(_component_JobConfiguration, {
                    status: _ctx.currentJobStatus,
                    type: _ctx.freqType,
                    key: _ctx.currentJob,
                    isBrokerJob: _ctx.orderBatchJobs.includes(_ctx.currentJob) ? true : false
                  }, null, 8, ["status", "type", "isBrokerJob"]))
                ], 512)), [
                  [_vShow, _ctx.currentJob]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}