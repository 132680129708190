import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('New broker run')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_input, {
              label: _ctx.translate('Name'),
              "label-placement": "fixed",
              placeholder: _ctx.currentDateTime = _ctx.getCurrentDateTime(),
              modelValue: _ctx.jobName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.jobName) = $event))
            }, null, 8, ["label", "placeholder", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "start",
              icon: _ctx.ticketOutline
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_select, {
              label: _ctx.translate('Order parking'),
              interface: "popover",
              value: _ctx.batchFacilityId,
              onIonChange: _cache[1] || (_cache[1] = ($event: any) => {_ctx.batchFacilityId = $event['detail'].value; _ctx.updateCustomParameters()})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_select_option, { value: "_NA_" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Brokering queue")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_select_option, { value: "PRE_ORDER_PARKING" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Pre-order parking")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_select_option, { value: "BACKORDER_PARKING" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Back-order parking")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["label", "value"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "start",
              icon: _ctx.warningOutline
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_toggle, {
              checked: _ctx.unfillableOrder,
              onIonChange: _cache[2] || (_cache[2] = ($event: any) => {_ctx.unfillableOrder = !_ctx.unfillableOrder; _ctx.updateCustomParameters()})
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('Unfillable orders')), 1)
              ]),
              _: 1
            }, 8, ["checked"])
          ]),
          _: 1
        }),
        (_ctx.customOptionalParameters.length || _ctx.customRequiredParameters.length)
          ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('More parameters')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.customRequiredParameters.length)
                  ? (_openBlock(), _createBlock(_component_ion_item_divider, {
                      key: 0,
                      color: "light"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate('Required Parameters')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customRequiredParameters, (parameter, index) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: index,
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        label: parameter.name,
                        placeholder: parameter.value ? parameter.value : parameter.name,
                        modelValue: parameter.value,
                        "onUpdate:modelValue": ($event: any) => ((parameter.value) = $event),
                        "helper-text": parameter.type
                      }, null, 8, ["label", "placeholder", "modelValue", "onUpdate:modelValue", "helper-text"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128)),
                (_ctx.customOptionalParameters.length)
                  ? (_openBlock(), _createBlock(_component_ion_item_divider, {
                      key: 1,
                      color: "light"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate('Optional Parameters')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customOptionalParameters, (parameter, index) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: index,
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        label: parameter.name,
                        placeholder: parameter.value ? parameter.value : parameter.name,
                        modelValue: parameter.value,
                        "onUpdate:modelValue": ($event: any) => ((parameter.value) = $event),
                        "helper-text": parameter.type
                      }, null, 8, ["label", "placeholder", "modelValue", "onUpdate:modelValue", "helper-text"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('No parameters available')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })),
        _createVNode(_component_ion_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('Schedule')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.timeOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_select, {
                  label: _ctx.translate('Run time'),
                  interface: "popover",
                  placeholder: _ctx.translate('Select'),
                  value: _ctx.runTime,
                  onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateRunTime($event)))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runTimes, (runTime) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: runTime.value,
                        value: runTime.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate(runTime.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["label", "placeholder", "value"]),
                _createVNode(_component_ion_modal, {
                  class: "date-time-modal",
                  "is-open": _ctx.isDateTimeModalOpen,
                  onDidDismiss: _cache[5] || (_cache[5] = () => _ctx.isDateTimeModalOpen = false)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_content, { "force-overscroll": "false" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_datetime, {
                          "show-default-buttons": "",
                          "hour-cycle": "h23",
                          value: _ctx.runTime ? (_ctx.isCustomRunTime(_ctx.runTime) ? _ctx.getDateTime(_ctx.runTime) : _ctx.getDateTime(_ctx.DateTime.now().toMillis() + _ctx.runTime)) : _ctx.getNowTimestamp(),
                          onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateCustomTime($event)))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["is-open"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.timerOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_select, {
                  label: _ctx.translate('Frequency'),
                  value: _ctx.jobStatus,
                  "interface-options": { header: _ctx.translate('Frequency') },
                  interface: "popover",
                  placeholder: _ctx.translate('Disabled'),
                  onIonChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.jobStatus = $event.detail.value)),
                  onIonDismiss: _cache[7] || (_cache[7] = ($event: any) => (_ctx.jobStatus == 'CUSTOM' && _ctx.setCustomFrequency()))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frequencyOptions, (freq) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: freq.id,
                        value: freq.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(freq.description), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["label", "value", "interface-options", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_fab, {
      disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.isRequiredParametersMissing,
      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateJob())),
      vertical: "bottom",
      horizontal: "end",
      slot: "fixed"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_fab_button, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.checkmarkDoneOutline }, null, 8, ["icon"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["disabled"])
  ], 64))
}