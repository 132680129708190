import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("More options")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewJobHistory(_ctx.job))),
            button: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.timeOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("History")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyJobInformation(_ctx.job))),
            button: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.copyOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("Copy details")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePinnedJobs(_ctx.job?.systemJobEnumId))),
            button: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.pinOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("Pin job")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.runJobNow(_ctx.job))),
            lines: "none",
            button: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.flashOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("Run now")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}