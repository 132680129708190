import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-689708b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  slot: "label",
  class: "ion-text-wrap"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  slot: "label",
  class: "ion-text-wrap"
}
const _hoisted_5 = {
  slot: "label",
  class: "ion-text-wrap"
}
const _hoisted_6 = {
  slot: "label",
  class: "ion-text-wrap"
}
const _hoisted_7 = { slot: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_ctx.type === 'products')
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_ion_item, { lines: "none" }, {
          default: _withCtx(() => [
            _createElementVNode("h1", null, _toDisplayString(_ctx.translate("Products")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.calendarClearOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Last run")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, {
                  class: "ion-text-wrap",
                  slot: "end"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.previousOccurrence ? _ctx.getTime(_ctx.previousOccurrence) : _ctx.translate('No previous occurrence')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.timeOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_select, {
                  interface: "popover",
                  placeholder: _ctx.translate('Select'),
                  value: _ctx.runTime,
                  onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateRunTime($event)))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("Run time")), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runTimes, (runTime) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: runTime.value,
                        value: runTime.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate(runTime.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["placeholder", "value"]),
                _createVNode(_component_ion_modal, {
                  "is-open": _ctx.isDateTimeModalOpen,
                  onDidDismiss: _cache[2] || (_cache[2] = () => _ctx.isDateTimeModalOpen = false)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_content, { "force-overscroll": "false" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_datetime, {
                          "show-default-buttons": "",
                          "hour-cycle": "h23",
                          value: _ctx.runTime ? (_ctx.isCustomRunTime(_ctx.runTime) ? _ctx.getDateTime(_ctx.runTime) : _ctx.getDateTime(_ctx.DateTime.now().toMillis() + _ctx.runTime)) : _ctx.getNowTimestamp(),
                          onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCustomTime($event)))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["is-open"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                (!Object.keys(_ctx.generateCustomParameters).length)
                  ? (_openBlock(), _createBlock(_component_ion_chip, {
                      key: 0,
                      onClick: _ctx.openJobCustomParameterModal,
                      outline: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate('Add custom parameters')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generateCustomParameters, (value, name) => {
                          return (_openBlock(), _createBlock(_component_ion_chip, {
                            onClick: _ctx.openJobCustomParameterModal,
                            outline: "",
                            color: value ? undefined :'danger',
                            key: name
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(name) + ": " + _toDisplayString(value), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick", "color"]))
                        }), 128))
                      ]),
                      _: 1
                    })),
                _createVNode(_component_ion_button, {
                  onClick: _ctx.openJobCustomParameterModal,
                  id: "open-modal",
                  slot: "end",
                  fill: "clear"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.listCircleOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_button, {
          disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.isRequiredParametersMissing,
          size: "small",
          fill: "outline",
          expand: "block",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.runJob('Products')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("Run import")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]))
    : (_openBlock(), _createElementBlock("section", _hoisted_3, [
        _createVNode(_component_ion_item, { lines: "none" }, {
          default: _withCtx(() => [
            _createElementVNode("h1", null, _toDisplayString(_ctx.translate("Orders")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.calendarClearOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Last run")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.previousOccurrence ? _ctx.getTime(_ctx.previousOccurrence) : _ctx.translate('No previous occurrence')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { button: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.timeOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_select, {
                  interface: "popover",
                  placeholder: _ctx.translate('Select'),
                  value: _ctx.runTime,
                  onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateRunTime($event)))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("Run time")), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runTimes, (runTime) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: runTime.value,
                        value: runTime.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate(runTime.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["placeholder", "value"]),
                _createVNode(_component_ion_modal, {
                  class: "date-time-modal",
                  "is-open": _ctx.isDateTimeModalOpen,
                  onDidDismiss: _cache[6] || (_cache[6] = () => _ctx.isDateTimeModalOpen = false)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_content, { "force-overscroll": "false" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_datetime, {
                          "show-default-buttons": "",
                          "hour-cycle": "h12",
                          value: _ctx.runTime ? (_ctx.isCustomRunTime(_ctx.runTime) ? _ctx.getDateTime(_ctx.runTime) : _ctx.getDateTime(_ctx.DateTime.now().toMillis() + _ctx.runTime)) : _ctx.getNowTimestamp(),
                          onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateCustomTime($event)))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["is-open"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.flagOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_select, {
                  value: "open",
                  "interface-options": _ctx.customOrderOptions,
                  interface: "popover"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translate("Order status")), 1),
                    _createVNode(_component_ion_select_option, { value: "open" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Open")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["interface-options"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.sendOutline
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_select, {
                  value: "unshipped",
                  "interface-options": _ctx.customFulfillmentOptions,
                  interface: "popover"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("Fulfillment status")), 1),
                    _createVNode(_component_ion_select_option, { value: "unshipped" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Unfulfilled")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["interface-options"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  modelValue: _ctx.lastShopifyOrderId,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.lastShopifyOrderId) = $event)),
                  placeholder: _ctx.translate('Internal Shopify Order ID')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("Last Shopify Order ID")), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                (!Object.keys(_ctx.generateCustomParameters).length)
                  ? (_openBlock(), _createBlock(_component_ion_chip, {
                      key: 0,
                      onClick: _ctx.openJobCustomParameterModal,
                      outline: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate('Add custom parameters')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generateCustomParameters, (value, name) => {
                          return (_openBlock(), _createBlock(_component_ion_chip, {
                            onClick: _ctx.openJobCustomParameterModal,
                            outline: "",
                            color: value ? undefined :'danger',
                            key: name
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(name) + ": " + _toDisplayString(value), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick", "color"]))
                        }), 128))
                      ]),
                      _: 1
                    })),
                _createVNode(_component_ion_button, {
                  onClick: _ctx.openJobCustomParameterModal,
                  id: "open-modal",
                  slot: "end",
                  fill: "clear"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.listCircleOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_button, {
          size: "small",
          fill: "outline",
          expand: "block",
          disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.isRequiredParametersMissing,
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.runJob('Orders')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("Run import")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]))
}